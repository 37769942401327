<template>
  <div>
    <div class="contact--content">
      <div class="row">
        <div class="col-12 col-lg-6" v-for="(contact, i) in data.list" :key="i">
          <contactItem
            :title="contact.title"
            :contactList="contact.contactList"
          ></contactItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contactItem from "@/views/Contact/parts/contactItem";

export default {
  name: "list",
  components: { contactItem },
  data() {
    return {};
  },
  props: {
    data: Object,
    slug: String
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.contact {
  &--content {
    padding: 25px 0;
    @include respond-to(screen-lg) {
      padding: 50px 0;
    }
    h3 {
      line-height: 1;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    p {
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
}
</style>
